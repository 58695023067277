import { CircularProgress, CssBaseline, Grid, Typography, useMediaQuery } from '@mui/material';

import EtopsLogo from '../assets/logo_area.png';

import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import BackgroundImg from '../assets/theme_img.png';
import { useState } from 'react';
import theme from '../theme';
import useProviders from '../hooks/useProviders';
import CenterLayout from '../components/CenterLayout';
import Login from '../screens/Login';
import ProviderList from '../screens/ProviderList';

const Container = styled('div')`
  display: flex;
  justify-content: flex-start;
  height: 100vh;
  ${({ theme }) => theme.breakpoints.down(768)} {
    justify-content: center;
    align-items: center;
  }
`;

const ImageWrapper = styled('div')`
  position: relative;
  width: 399px;
  background-image: url(${BackgroundImg});
  background-color: ${({ theme }) => theme.palette.mixed.dark};
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  ${({ theme }) => theme.breakpoints.down('lg')} {
    width: 350px;
  }
  ${({ theme }) => theme.breakpoints.down('md')} {
    width: 300px;
  }
`;

const ResponsiveBox = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 200px;
  ${({ theme }) => theme.breakpoints.down('lg')} {
    margin-left: 100px;
  }
  ${({ theme }) => theme.breakpoints.down(768)} {
    margin-left: 0;
  }
`;

export default () => {
  const matches = useMediaQuery('(min-width:768px)');

  const [token, setToken] = useState('');
  const [oauthUserId, setOauthUserid] = useState('');
  const [loadingProviders, providers] = useProviders();
  const [loadingOAuthAuthorization, setLoadingOAuthAuthorization] = useState(false);

  const loading = (
    loadingProviders ||
    loadingOAuthAuthorization
  );

  return (
    <Container>
      <CssBaseline />
      {matches && (
        <ImageWrapper>
          <img
            src={EtopsLogo}
            width={224}
            style={{ position: 'absolute', top: '55px', right: '49px' }}
            alt='Logo'
          />
        </ImageWrapper>
      )}
      <ResponsiveBox>
        <Grid
          container
        >
          <Grid item xs={12}>
            {!loadingOAuthAuthorization && (
              <Typography
                variant='h2'
                sx={{
                  color: theme.palette.primary.dark,
                  font: 'normal normal normal 24px/32px Roboto',
                  fontWeight: 'bold',
                  marginBottom: 0
                }}
                mb={1.5}
              >
                Openwealth Gateway
              </Typography>
            )}
          </Grid>
          <Grid item>
            {token ? (
              loading ? (
                <CenterLayout>
                  <CircularProgress />
                </CenterLayout>
              ) : (
                <ProviderList
                  providers={providers}
                  oauthUserId={oauthUserId}
                  token={token}
                  setLoadingOAuthAuthorization={setLoadingOAuthAuthorization}
                  loading={loading}
                />
              )
            ) : (
              <Login
                setOauthUserid={setOauthUserid}
                setToken={setToken}
                matches={matches}
              />
            )}
          </Grid>
        </Grid>
      </ResponsiveBox>
    </Container>
  );
}
