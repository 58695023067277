import axios from "axios";
import * as config from "../config";
import { Provider } from '../types/provider';

class BackendApi {
  baseUrl = `${config.apiBaseUrl}/api/${config.apiVersion}`;

  async listProviders() {
    const response = await axios.get(`${this.baseUrl}/providers`);
    return response.data as Provider[];
  }

  async retrieveProvider(providerId: string) {
    const response = await axios.get(`${this.baseUrl}/providers/${providerId}`);
    return response.data as Provider;
  }

  async login(email: string, password: string) {
    const response = await axios.post(`${this.baseUrl}/users/login`, { email, password });
    return response.data as {
      token: string;
    };
  }

  async authorizeOAuth(providerId: string, oauthUserId: string, token: string) {
    const response = await axios.post(
      `${this.baseUrl}/oauth/providers/${providerId}/users/${oauthUserId}/authorization`,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    );
    return response.data as { redirectUrl: string; };
  }
}

export default new BackendApi();