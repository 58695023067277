import React from 'react';
import EtopsLogoIcon from '../assets/logo_icon.png';
import { styled } from '@mui/material/styles';
import { Button, Grid, TextField, Tooltip } from '@mui/material';
import backendApi from '../apiClients/backendApi';
import InfoIcon from '@mui/icons-material/Info';

const StyledTextField = styled(TextField)`
  & .MuiFormLabel-root.MuiInputLabel-root {
    color: ${({ theme }) => theme.palette.gray.main};
  }
`;

const StyledButton = styled(Button)`
  width: 168px;
  padding: 10px;
  background-color: ${({ theme }) => theme.palette.green.dark};
  &:hover {
    background-color: ${({ theme }) => theme.palette.green.dark};
    opacity: 0.7;
  }
`;

const AuthForm = styled('form')`
  display: flex;
  flex-direction: column;
  width: 400px;
  ${({ theme }) => theme.breakpoints.down('lg')} {
    width: 350px;
  }
  ${({ theme }) => theme.breakpoints.down('md')} {
    width: 300px;
  }
`;

interface LoginProps {
  setOauthUserid: (oauthUserId: string) => void;
  setToken: (token: string) => void;
  matches: boolean;
}

export default ({ setOauthUserid, setToken, matches }: LoginProps) => {
  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const email = data.get('email') as string;
    const password = data.get('password') as string;
    const oauthUserId = data.get('oauthUserId') as string;
    setOauthUserid(oauthUserId);

    if (!email) {
      alert(`User E-Mail required.`);
      return;
    }
    if (!password) {
      alert(`User Password required.`);
      return;
    }
    if (!oauthUserId) {
      alert('E-Banking Number required.');
      return;
    }

    try {
      const result = await backendApi.login(email, password);
      setToken(result?.token);
    } catch (err) {
      console.error(err);
      alert('Invalid Credentials,\ncheck your username and password');
    }
  };

  return (
    <Grid container item>
      <Grid
        item
        xs={12}
        sx={{
          fontSize: 14,
          marginBottom: 4
        }}
      >
        <div>Enter your details below:</div>
      </Grid>
      <Grid item>
        <AuthForm onSubmit={handleSubmit}>
          {!matches && (
            <img src={EtopsLogoIcon} width={110} alt='LogoIcon' style={{ marginBottom: 24 }} />
          )}
          <StyledTextField
            required
            fullWidth
            id='email'
            label='Email'
            type='email'
            name='email'
            autoComplete='email'
            autoFocus
          />
          <StyledTextField
            required
            fullWidth
            name='password'
            label='Password'
            type='password'
            id='password'
            autoComplete='current-password'
            sx={{ margin: '25px 0 0 0' }}
          />
          <Grid container item>
            <Grid item>
              <StyledTextField
                required
                fullWidth
                name='oauthUserId'
                label='E-Banking Number'
                id='oauthUserId'
                autoComplete='oauthUserId'
                sx={{ margin: '25px 0 14px' }}
              />
            </Grid>
            <Grid item sx={{
              margin: '37px 0 0 18px'
            }}>
              <Tooltip title={<div style={{ fontSize: 12 }}>OAuth User ID</div>}>
                <InfoIcon style={{ fontSize: 25 }} />
              </Tooltip>
            </Grid>
          </Grid>
          <StyledButton
            type='submit'
            fullWidth
            variant='contained'
            sx={{ color: (theme: any) => theme.palette.white.dark }}
          >
            Login
          </StyledButton>
        </AuthForm>
      </Grid>
    </Grid>
  );
};