import { useEffect, useState } from "react";
import backendApi from "../apiClients/backendApi";
import { Provider } from '../types/provider';

export default () => {
  const [loading, setLoading] = useState(true);
  const [providers, setProviders] = useState<Provider[]>();

  useEffect(() => {
    if (!loading) {
      setLoading(false);
      return;
    }

    let canceled = false;

    (async () => {
      try {
        const providers = await backendApi.listProviders();
        if (!canceled) setProviders(providers);
      } catch (err) {
        console.error(err);
      } finally {
        setLoading(false);
      }
    })();

    return () => {
      canceled = true;
    };
  }, [loading]);

  return [
    loading,
    providers,
    () => setLoading(true)
  ] as [
      boolean,
      Provider[],
      () => void
    ];
};