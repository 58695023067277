import React from 'react';
import UBSLogo from '../assets/logo_ubs.svg';
import CSLogo from '../assets/logo_credit_suisse.svg';
import ZHKBLogo from '../assets/logo_zuercher_kantonalbank.svg';
import StKBLogo from '../assets/logo_stgaller_kantonalbank.svg';
import { Provider } from '../types/provider';

interface ProviderLogoProps {
  provider: Provider
}

export default ({ provider }: ProviderLogoProps) => {
  // function setSvgDimensions(svg: string, widht: number, height: number) {
  //   if (!svg.includes('width') || !svg.includes('height')) return;
  // }

  const providerImageMap = {
    'UBS Switzerland AG': UBSLogo,
    'CREDIT SUISSE (Schweiz) AG': CSLogo,
    'Zürcher Kantonalbank': ZHKBLogo,
    'St.Galler Kantonalbank': StKBLogo
  };

  // TODO: Use logo set in provider and set dimensions properly. Clarify why UBS logo is invalid.
  // const blob = new Blob([provider.logoSvg], {type: 'image/svg+xml'});
  // const url = URL.createObjectURL(blob);

  return Object.prototype.hasOwnProperty.call(providerImageMap, provider.companyName) ? (
    // @ts-ignore
    <img src={providerImageMap[provider.companyName]} alt='not found' />
  ) : null;
};