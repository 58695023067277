import React from 'react';
import { Grid } from '@mui/material';
import ProviderItem from '../components/ProviderItem';
import { Provider } from '../types/provider';
import backendApi from '../apiClients/backendApi';

interface ProviderListProps {
  providers: Provider[];
  oauthUserId: string;
  token: string;
  setLoadingOAuthAuthorization: (status: boolean) => void;
  loading: boolean;
}

export default ({ providers, oauthUserId, token, setLoadingOAuthAuthorization, loading }: ProviderListProps) => {
  const onProviderClick = (provider: Provider) => async () => {
    if (!oauthUserId) {
      alert(`OAuth User ID required.`);
      return;
    }
    if (!token) {
      alert(`Login required.`);
      return;
    }

    try {
      setLoadingOAuthAuthorization(true);
      const { redirectUrl } = await backendApi.authorizeOAuth(provider.providerId, oauthUserId, token);
      window.location.href = redirectUrl;
    } catch (err) {
      console.error(err);
    } finally {
      setLoadingOAuthAuthorization(false);
    }
  };

  return (
    <Grid container item>
      {!loading && (
        <Grid
          item
          xs={12}
          sx={{
            fontSize: 14,
            marginBottom: 4
          }}
        >
          <div>Choose your provider:</div>
        </Grid>
      )}
      <Grid
        container
        item
        columns={8}
        xs={4}
        columnSpacing={3}
        rowSpacing={3}
      >
        {
          providers.map(provider => (
            <Grid
              key={provider.providerId}
              item
              xs={4}
            >
              <ProviderItem
                onProviderClick={onProviderClick(provider)}
                provider={provider}
              />
            </Grid>
          ))
        }
      </Grid>
    </Grid>
  );
};