import React from 'react';
import { Provider } from '../types/provider';
import { Card, CardContent, Typography } from '@mui/material';
import ProviderLogo from './ProviderLogo';
import CenterLayout from './CenterLayout';
import { styled } from '@mui/material/styles';

const ComingSoonLabel = styled('div')`
  width: 96px;
  height: 20px;
  background: #22343D 0 0 no-repeat padding-box;
  border-radius: 8px;
  opacity: 1;
  margin: 15px 0 -34px;

  .comingsoon-text {
    width: 71px;
    height: 16px;
    font: normal normal bold 12px/16px Roboto;
    letter-spacing: 0;
    color: #ffffff;
    text-align: center;
    opacity: 1;
    margin: 2px 0 0 10px;
  }
`;

interface ProviderCardProps {
  provider: Provider;
  onProviderClick: (provider: Provider) => void;
}

export default ({ provider, onProviderClick }: ProviderCardProps) => {
  return (
    <Card
      sx={{
        minWidth: 200,
        display: 'flex',
        justifyContent: 'center',
        height: 1,
        position: 'relative',
        overflow: 'visible'
      }}
      onClick={() => provider.doesUseOpenWealth && onProviderClick(provider)}
    >
      <CardContent>
        <CenterLayout>
          <div style={{
            margin: '30px 0 0',
            height: '30px'
          }}>
            <ProviderLogo provider={provider} />
          </div>
          <Typography
            variant="h6"
            component="div"
            sx={{
              marginTop: 3,
              marginBottom: 1
            }}
          >
            {provider.companyName}
          </Typography>
          {!provider.doesUseOpenWealth && (
            <ComingSoonLabel>
              <div className='comingsoon-text'>Coming soon</div>
            </ComingSoonLabel>
          )}
        </CenterLayout>
      </CardContent>
    </Card>
  );
};